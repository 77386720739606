
import { Component, Vue, Prop } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import UserManagementService from "@/services/user-management";
import hrmService from "@/services/hrm-service";
import projectService from "@/services/project-service";
import Utils from '@/utils/utils'
import PopUp from "@/components/PopUp.vue";
import Loader from "@/components/loader.vue"; // @ is an alias to /src


@Component({
    components: {
        PopUp,
        Header,
        Loader
    },
})
export default class AssignShifts extends Vue {
    loading = true;
    public import_file = null;
    public forUploaded = true;
    searchSelected = "project"
    // For Modal
    modalShow = false;
    modalMessage: string[] = [];
    modalSimpleMessage: string = "";
    modalType = "";


    closeModal() {
        this.modalShow = false;
        this.modalMessage = [];
        this.modalType = "";
        this.modalSimpleMessage = ""
    }
    showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
        this.modalShow = true;
        this.modalMessage = array;
        this.modalType = type;
        this.modalSimpleMessage = message
    }
    // modal end

    forUpdate = false
    index = -1
    project_index = -1
    shift_index = -1
    currentPage = 1
    items = [
    ];
    get rows() {
        return this.items.length;
    }

    public selected_employees: any = [];
    public selected_projects: any = []

    public selected_shifts: any = [];

    payload: any = {
        off_mon: 0,
        off_tue: 0,
        off_wed: 0,
        off_thu: 0,
        off_fri: 0,
        off_sat: 0,
        off_sun: 1,
    }
    typeOptions = [
        { value: 0, text: 'On' },
        { value: 1, text: 'Off' }
    ]
    fields_emp = [
        { key: "employee__title", sortable: true },
        { key: "shift__title", sortable: true },
        { key: "off_mon", label: "Monday" },
        { key: "off_tue", label: "Tuesday" },
        { key: "off_wed", label: "Wednesday" },
        { key: "off_thu", label: "Thursday" },
        { key: "off_fri", label: "Friday" },
        { key: "off_sat", label: "Saturday" },
        { key: "off_sun", label: "Sunday" },
    ]
    fields_project = [
        { key: "project_title_to", label: "Project title", sortable: true },
        { key: "shift__title", sortable: true },
        { key: "off_mon", label: "Monday" },
        { key: "off_tue", label: "Tuesday" },
        { key: "off_wed", label: "Wednesday" },
        { key: "off_thu", label: "Thursday" },
        { key: "off_fri", label: "Friday" },
        { key: "off_sat", label: "Saturday" },
        { key: "off_sun", label: "Sunday" },
    ]
    searchOpt = [
        { value: "employee", text: 'Employee Shifts' },
        { value: "project", text: "Projects Shifts" },
    ]

    mounted() {
        this.retreive()
    }

    search_employee(title: any, index: any) {
        this.index = index;
        if (title.length > 2) {
            UserManagementService.get_Employee_by_Title(title)
                .then((res) => {
                    this.selected_employees = res.data;
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        if (title.length == 0) {
            this.selected_employees = [];
        }
    }

    search_shifts(title: any, index: any) {
        this.shift_index = index;
        if (title.length > 2) {
            hrmService.getWorkingDaysByTitle(title)
                .then((res) => {
                    this.selected_shifts = res.data;
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        if (title.length == 0) {
            this.selected_shifts = [];
        }
    }
    searchProject(title: any, index: any) {
        if (title.length == 0) {
            this.selected_projects = [];
            return
        }
        projectService.getCurrentProject(title).then((response) => {
            this.selected_projects = response.data.result
        })

    }


    get_shifts(data: any) {
        this.payload.shifts_title = data.title
        this.payload.shift = data.id
        this.selected_shifts = [];
    }

    onRowClicked(data: any) {
        this.forUpdate = true
        // if (this.searchSelected === "employee") {
        //     this.payload['employee_title'] = data['employee__title']
        //     this.payload['employee'] = data['employee']
        // }
        // else {
        this.payload['project_title'] = data['project_title_to']
        this.payload['project'] = data['project__id']

        // }

        this.payload['shifts_title'] = data['shift__title']
        this.payload['shift'] = data['shift']
        this.payload['off_mon'] = data['off_mon'] == false ? 0 : 1
        this.payload['off_tue'] = data['off_tue'] == false ? 0 : 1
        this.payload['off_wed'] = data['off_wed'] == false ? 0 : 1
        this.payload['off_thu'] = data['off_thu'] == false ? 0 : 1
        this.payload['off_fri'] = data['off_fri'] == false ? 0 : 1
        this.payload['off_sat'] = data['off_sat'] == false ? 0 : 1
        this.payload['off_sun'] = data['off_sun'] == false ? 0 : 1
        this.payload['id'] = data['id']
        if (this.searchSelected === "project") {
            this.$root.$emit("bv::toggle::collapse", "sidebar-variant-shifts");
        }
    }
    get_data(data: any) {
        this.payload.employee_title = data.title
        this.payload.employee = data.id
        this.selected_employees = [];
    }
    get_project_data(data: any) {
        this.payload.project_title = data.title
        this.payload.project = data.id
        this.selected_projects = [];
    }
    retreiveByOptions(opt: any) {
        this.retreive();
    }
    error: any = ""

    create() {
        this.error = ""
        const error_list = Utils.getEmptyKeys(this.payload, ['project_title', 'shifts_title'])
        if (error_list.length > 0) {
            this.error = Utils.generateErrorMessage(error_list)
        }
        else {

            hrmService.createRoasterByProject(this.payload, this.searchSelected).then((resp) => {
                this.clean()
                this.retreive()
                this.forUpdate = false
                this.showModal("Succesfully created", [], "success");
                this.$root.$emit("bv::toggle::collapse", "sidebar-variant-shifts");

            }).catch((err) => {
                if (err.response && err.response.status === 400) {
                    // Handle the 400 Bad Request error
                    this.showModal("", err.response.data, "errorArray");
                } else {
                    // Handle other types of errors (e.g., network issues, server errors)
                    console.error('Unexpected error:', err);
                    this.showModal('An unexpected error occurred', [], "error");
                }
            })


            // if (!this.forUpdate) {
            //     if(this.searchSelected === 'project'){
            //         hrmService.createRoasterByProject(this.payload, this.searchSelected).then((resp) => {
            //             this.clean()
            //             this.retreive()
            //             this.forUpdate = false
            //             this.showModal("Succesfully created", [], "success");
            //             this.$root.$emit("bv::toggle::collapse", "sidebar-variant-shifts");

            //         }).catch((err) => {
            //             if (err.response && err.response.status === 400) {
            //                 // Handle the 400 Bad Request error
            //                 this.showModal("", err.response.data, "errorArray");
            //             } else {
            //                 // Handle other types of errors (e.g., network issues, server errors)
            //                 console.error('Unexpected error:', err);
            //                 this.showModal('An unexpected error occurred', [], "error");
            //             }
            //         })
            //     }
            //     else{
            //         hrmService.createRoasterByEmployee(this.payload, this.searchSelected).then((resp) => {
            //             this.clean()
            //             this.retreive()
            //             this.forUpdate = false
            //             this.showModal("Succesfully created", [], "success");
            //             this.$root.$emit("bv::toggle::collapse", "sidebar-variant-shifts");

            //         }).catch((err) => {
            //             if (err.response && err.response.status === 400) {
            //                 // Handle the 400 Bad Request error
            //                 this.showModal("", err.response.data, "errorArray");
            //             } else {
            //                 // Handle other types of errors (e.g., network issues, server errors)
            //                 console.error('Unexpected error:', err);
            //                 this.showModal('An unexpected error occurred', [], "error");
            //             }
            //         })
            //     }
            // } else if(this.forUpdate && this.searchSelected==="employee") {
            //     hrmService.updateRoaster(this.payload, this.payload.id).then((resp) => {
            //         this.clean()
            //         this.retreive()
            //         this.forUpdate = false
            //         this.showModal("Succesfully Updated", [], "success");
            //         this.$root.$emit("bv::toggle::collapse", "sidebar-variant-shifts");

            //     }).catch((err) => {
            //         if (err.response && err.response.status === 400) {
            //             // Handle the 400 Bad Request error
            //             this.showModal("", err.response.data, "errorArray");
            //         } else {
            //             // Handle other types of errors (e.g., network issues, server errors)
            //             console.error('Unexpected error:', err);
            //             this.showModal('An unexpected error occurred', [], "error");
            //         }
            //     })
            // }
        }
    }
    retreive() {
        this.loading = true;
        hrmService.getRoasterData(this.searchSelected).then((resp) => {
            this.items = resp.data.result
            this.loading = false
        }).catch((e) => {
            this.loading = false;
            console.log(e);
        });
    }
    renderOffBadge(value: string) {
        const variant = value ? 'danger' : 'success';
        const text = value ? 'Off' : 'On';
        const badgeHtml = `<b-badge class="badge-${variant} pill" style="font-size: larger;">${text}</b-badge>`;
        return badgeHtml;
    }
    clean() {
        this.payload = {
            off_mon: 0,
            off_tue: 0,
            off_wed: 0,
            off_thu: 0,
            off_fri: 0,
            off_sat: 0,
            off_sun: 1,
        }
        this.forUpdate = false;
    }
    shiftsUpload() {
        if (this.import_file !== null) {
            hrmService.uploadBulkRoaster(this.import_file).then((response) => {
                if (response.data.status == 200) {
                    this.showModal("Succesfully Uploaded", [], "success");
                }

            })
        }
    }

}
